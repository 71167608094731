<template>
  <Modal
    name="common_success"
    size="md"
    :successIcon="successIcon"
    :title="title"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="success_modal">
        <p class="modal__text _centered-text" v-if="text" v-html="text"></p>
      </div>
      <div class="modal__footer">
        <button
          v-if="btnClose"
          @click="closeModal('common_success')"
          class="btn btn--yellow w-100"
        >
          Хорошо
        </button>
        <button
          v-if="checkButton"
          class="btn btn--yellow"
          @click="closeModal('common_success'), toCheckUpload()"
        >
          Регистрируй чек
        </button>
        <router-link
          :to="{ name: 'Profile', query: { tab: 'voting' } }"
          v-if="toProfile"
          @click.native="closeModal('common_success')"
          class="btn btn--yellow"
          >Хорошо</router-link
        >
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      text: "",
      title: "Спасибо!",
      successIcon: false,
      btnClose: false,
      checkButton: false,
      toProfile: true,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },

    beforeOpen(event) {
      this.text = event.params.text;
      if (event.params.title) {
        this.title = event.params.title || "Спасибо!";
      }
      this.successIcon = event.params.successIcon;
      this.checkButton = event.params.checkButton;
      this.btnClose = event.params.btnClose;
      this.toProfile = event.params.toProfile;
    },
  },
};
</script>
<style lang="scss">
.success_modal-btn {
  margin-top: rem(30px);
}
</style>
