<template>
  <modal
    :name="name"
    :adaptive="true"
    :scrollable="true"
    height="auto"
    @before-open="beforeOpen"
    :clickToClose="!withoutClosing"
  >
    <div
      class="modal"
      :class="[
        'modal-' + name,
        size ? 'modal--' + size : 'modal--md',
        success ? 'modal-success' : '',
        withoutClosing ? '_withoutClosing' : '',
      ]"
    >
      <div
        class="modal__close"
        v-if="!withoutClosing"
        @click="closeModal(name)"
      ></div>
      <div class="modal__header" v-if="title">
        <img
          v-if="successIcon"
          src="../assets/images/icons/success.svg"
          alt=""
          class="modal__info-icon"
        />
        <img
          v-if="errorIcon"
          src="../assets/images/icons/warning.svg"
          alt=""
          class="modal__info-icon"
        />
        <h2
          class="modal__title"
          :class="success ? 'modal-success__title' : ''"
          v-html="title"
        ></h2>
      </div>
      <slot name="content"></slot>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
    },
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    success: {
      type: Boolean,
    },
    successIcon: {
      type: Boolean,
    },
    errorIcon: {
      type: Boolean,
    },
    withoutClosing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showBurger() {
      this.closeModal(this.name);
      let header = document.querySelector(".header");
      if (!header.classList.contains("header--mob_active"))
        header.classList.add("header--mob_active");
      else header.classList.remove("header--mob_active");
    },
    closeModal(name) {
      this.$emit("closeModal", name);
      if (name === "signup") {
        // this.gtmEvent("drop_success", "do", "internal");
      }
    },
    beforeOpen(event) {
      this.$parent.beforeOpen(event);
    },
  },
  mounted() {
    // this.$modal.show("success_modal");
  },
};
</script>

<style lang="scss">
.vm--container {
  overflow: auto;
  z-index: 999999 !important;
  .vm--overlay {
    background: rgba($black, 0.8);
    backdrop-filter: blur(6px);
  }
}
.vm--modal {
  top: 0 !important;
  min-height: 100vh;
  justify-content: center;
  overflow: auto !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  display: flex;
  align-items: flex-end;
  box-shadow: none !important;
  background-color: transparent !important;

  @media (orientation: landscape) {
    padding: 0;
    align-items: center;
    overflow: visible !important;
    &::after {
      display: none;
    }
  }
}

.modal {
  position: absolute;
  top: vh(82px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: calc(100% - rem(32px));
  max-width: 100vw;
  padding: rem(64px) rem(32px);
  background: $black;
  border: rem(2px) solid $orange;
  border-radius: rem(36px);
  box-shadow: 0px 0px 72px 0px rgba(255, 149, 0, 1);
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    display: block;
    width: 100%;
    height: rem(100px);
    pointer-events: none;
  }
  &-check_photo {
    min-height: auto;
  }
  &-games_popup {
    top: 2vw;
    padding: rem(60px) 0 0;
    overflow: hidden;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: rem(16px);
    right: rem(16px);
    @include size(rem(40px));
    background: rgba(255, 255, 255, 0.15)
      url("../assets/images/icons/close.svg") no-repeat center;
    background-size: rem(13px);
    border-radius: 50%;
    transition: 0.4s;
    &:hover {
      transform: rotate(90deg);
    }
  }

  &__title {
    margin-bottom: rem(20px);
    text-transform: uppercase;
    color: #fff;
    font-size: rem(24px);
    line-height: rem(32px);
    font-family: $bold;
    text-align: center;
    & + .modal__text {
      margin-top: rem(-7px);
    }
  }
  &__subtitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(10px);
    font-family: $reg;
    font-size: rem(14px);
    text-transform: uppercase;
    text-align: center;
    span {
      display: block;
      flex: 1 0 auto;
      height: 1px;
      background: #000;
      &:first-of-type {
        margin-right: rem(10px);
      }
      &:last-of-type {
        margin-left: rem(10px);
      }
    }
  }
  &__text {
    margin-top: rem(0px);
    text-align: center;
    font-family: $reg;
    font-size: rem(16px);
    line-height: rem(20px);
    b {
      display: block;
      text-transform: uppercase;
    }
    p {
      &:not(:last-of-type) {
        margin-bottom: rem(16px);
      }
    }
    ul {
      margin: rem(10px) 0;
      li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: rem(14px);
        font-size: rem(18px);
        text-align: left;
        &:not(:last-of-type) {
          margin-bottom: rem(14px);
        }
      }
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        @include size(rem(30px));
        margin-right: rem(14px);
        line-height: 1;
        flex-shrink: 0;
        font-style: normal;
        font-family: $bold;
        font-size: rem(14px);
        background: #deb22e;
        border-radius: 50%;
      }
    }
  }
  &__info-icon {
    margin: auto;
    margin-bottom: rem(12px);
    max-height: rem(40px);
    width: auto;
    object-fit: contain;
  }

  &__another-option {
    margin: rem(32px) 0 0;
    font-family: $reg;
    font-size: rem(16px);
    text-transform: uppercase;
    text-align: center;
    a {
      border-bottom: 1px solid currentColor;
      &:hover {
        border-color: transparent;
      }
    }
  }
  &__footer {
    margin-top: rem(32px);
    text-align: center;
    .btn {
      width: 100%;
    }
  }
  &__prize {
    display: flex;
    align-items: center;
    margin-bottom: rem(20px);
    &-img {
      width: 100%;
      height: auto;
      margin-right: rem(20px);
      flex-shrink: 0;
      &-big {
        height: rem(140px);
        margin-right: auto;
        margin-left: auto;
      }
    }
    &-text {
      color: $brown;
      font-size: rem(18px);
      line-height: rem(20px);
      text-transform: uppercase;
    }
  }
  .success_modal {
    &__text {
      margin-top: 0;
      font-size: rem(14px);
      margin-bottom: rem(20px);
      text-align: center !important;
      font-family: $reg;
      text-transform: none;
    }
    &-back {
      font-size: rem(12px);
      text-transform: uppercase;
      font-family: $bold;
      text-align: center;
    }
    &-image {
      margin: auto;
    }
  }
  .error_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: rem(14px);
    img {
      margin-bottom: rem(24px);
    }
    &-text {
      font-family: $reg;
      font-size: rem(14px);
      text-transform: none;
      margin-bottom: rem(25px);
    }
  }
  .btn {
    margin-left: auto;
    margin-right: auto;
  }
  &__popover-text {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: rem(20px);
    background: rgba($light, 0.6);
    color: $red;
    transform: translateY(-40px);
    transition: 0.6s ease-in-out;
    &:hover {
      transform: translateY(-100%);
    }
  }
  &-image {
    padding-top: rem(60px);
    padding-bottom: rem(55px);
  }
  &__iframe {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    margin-bottom: rem(30px);
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  &__games {
    text-align: center;
    text-transform: uppercase;
    iframe {
      width: 100%;
      height: 150vw;
    }
    &-title {
      margin-bottom: rem(10px);
      font-size: rem(30px);
      font-family: $bold;
    }
    &-text {
      margin-bottom: rem(120px);
      font-size: rem(16px);
    }
    &-show-more {
      margin: rem(20px) auto;
    }
    .btn {
      width: rem(230px);
    }
    &-table-wrapper {
      max-height: rem(475px);
      padding-right: rem(10px);
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: rem(4px);
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: $brown;
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $orange;
        transition: all 0.3s ease-in-out;
      }
    }
    &-list {
      position: relative;
      padding: 0 rem(5px) rem(30px) rem(15px);
      background: #1d1d1d;
      border: rem(2px) solid #404040;
      border-radius: rem(35px);
      box-shadow: 0px 0px rem(20px) 0px rgba(255, 149, 0, 1);
      &::before {
        content: "";
        display: block;
        width: rem(216px);
        height: rem(185px);
        margin: rem(-108px) auto rem(10px);
        background: url("../assets/images/finish.png") no-repeat center;
        background-size: contain;
      }
      table {
        width: 100%;
        table-layout: fixed;
        th {
          font-size: rem(24px);
          font-family: $medium;
          border-bottom: 1px solid #fff;
        }
        td {
          font-size: rem(16px);
          padding: rem(8px) 0;
          &:last-child {
            font-family: $medium;
          }
        }
        tr {
          &:not(:last-child) {
            td {
              border-bottom: 1px solid #fff;
            }
          }
        }
        th,
        td {
          &:first-child {
            position: relative;
            text-align: left;
            width: 70%;
            padding-right: rem(20px);
            overflow: hidden;
            white-space: nowrap;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              width: rem(20px);
              height: 100%;
              background: linear-gradient(to right, transparent, #1d1d1d);
            }
          }
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: rem(16px) 0;
    .btn {
      width: 100%;
    }
  }
  &-nav {
    display: flex;
    margin: 0 auto rem(32px);
    justify-content: center;
    gap: 0 rem(16px);
    border-bottom: 1px solid $orange;
    &__item {
      position: relative;
      padding-bottom: rem(19px);
      text-transform: uppercase;
      font-family: $bold;
      font-size: rem(24px);
      line-height: 1;
      color: $orange;
      opacity: 0.4;
      transition: 0.4s;
      overflow: hidden;
      cursor: default;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: rem(6px);
        background: $orange;
        border-radius: rem(4px) rem(4px) 0 0;
        transition: 0.4s;
        transform: translateY(100%);
      }
      &.active {
        opacity: 1;
        &::before {
          transform: translateY(0%);
        }
      }
      &:hover {
        opacity: 1;
        &::before {
          transform: translateY(0%);
        }
      }
    }
  }
  @media (orientation: landscape) {
    min-height: auto;
    top: 10vh;
    justify-content: flex-start;
    &--sm {
      width: rem(440px);
    }
    &--md {
      width: rem(700px);
    }
    &--lg {
      width: rem(758px);
      padding: rem(40px) rem(25px) rem(46px);
    }
  }
  @media (orientation: landscape) {
    min-height: auto;
    top: vh(100px);
    bottom: auto;
    justify-content: flex-start;
    border-radius: rem(36px);
    padding: rem(75px) rem(66px) rem(53px);
    &--md {
      width: rem(560px);
      padding: rem(56px) rem(50px);
    }
    &--lg {
      width: rem(800px);
      padding: rem(66px) rem(75px) rem(45px);
      .modal__subtitle {
        margin-bottom: rem(38px);
      }
      &.modal-image {
        margin-top: 10vh;
      }
      &.modal-video {
        width: 70vw;
      }
    }
    &-games_popup {
      top: 5vh;
      padding: 0;
    }
    &__games {
      &-title {
        font-size: rem(36px);
      }
      &-text {
        font-size: rem(18px);
      }
      &-list {
        padding: 0 rem(25px) rem(40px);
        border-radius: rem(65px);
        box-shadow: 0px 0px rem(50px) 0px rgba(255, 149, 0, 1);
      }
      iframe {
        height: 90vh;
      }
    }
    &-temporarily_unavailable {
      .modal__title {
        font-size: rem(40px);
      }
      .modal__text {
        font-size: rem(18px);
        line-height: 1.5;
        b {
          font-size: rem(26px);
          text-transform: uppercase;
        }
      }
    }
    &__title {
      margin-bottom: rem(30px);
      font-size: rem(32px);
      line-height: rem(40px);
    }
    &__text {
      // margin-top: rem(27px);
      font-size: rem(16px);
      ul {
        width: 70%;
        margin: rem(15px) auto 0;
        li {
          font-size: rem(20px);
        }
        i {
          @include size(rem(36px));
          font-size: rem(18px);
        }
      }
    }

    &__list {
      width: 70%;
    }
    &__subtitle {
      margin-top: rem(-8px);
      margin-bottom: rem(32px);
      font-size: rem(16px);
    }
    &__another-option {
      margin: rem(32px) 0 0;
      font-size: rem(16px);
    }
    &__prize {
      width: rem(430px);
      margin: 0 auto rem(30px);
      &-img {
        width: rem(380px);
        margin: auto;
        & + .modal__prize-text {
          height: rem(150px);
          margin: 0;
        }
      }
      &-text {
        font-size: rem(22px);
        line-height: rem(25px);
      }
    }
    &-common_success {
      .modal__title {
        font-size: rem(32px);
        line-height: rem(40px);
      }
    }
    &-success {
      &__title {
        color: #fff;
        font-size: rem(32px);
        line-height: rem(40px);
      }
    }
    &__info-icon {
      margin-top: rem(-40px);
      margin-bottom: rem(0px);
      max-height: rem(162px);
    }
    &__footer {
      width: rem(288px);
      max-width: 100%;
      margin: rem(32px) auto 0;
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      gap: rem(16px) 0;
    }
    &-nav {
      &__item {
        font-size: rem(32px);
      }
    }
  }
}
</style>
