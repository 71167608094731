<template>
  <Modal name="games_list_popup" @closeModal="switchPopup" size="md">
    <template v-slot:content>
      <div class="modal__games">
        <div class="modal__games-title">Рейтинг игроков</div>
        <div class="modal__games-text">
          Укажите в&nbsp;Личном Кабинете свой номер мобильного телефона,
          в&nbsp;случае выигрыша приз будет направлен на&nbsp;указанный номер
        </div>
        <div class="modal__games-list">
          <div class="modal__games-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>ИГрок</th>
                  <th>Баллы</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in list" :key="i.id">
                  <td>{{ i.email }}</td>
                  <td>{{ i.score }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            v-if="pages > 1 && pages != page"
            @click="loadMore()"
            class="modal__games-show-more btn btn--bordered _white"
          >
            Показать ещё
          </button>
          <button class="btn btn--yellow" @click="switchPopup()">
            на главную
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      src: null,
      text: null,
      page: 1,
      pages: null,
      perPage: 10,
      list: [],
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    switchPopup() {
      this.closeModal("games_list_popup");
      this.$modal.show("games_popup");
    },
    loadMore() {
      this.page++;
      const o = {
        page: this.page,
        "per-page": this.perPage,
      };
      this.$store.dispatch("GamesList", o).then((r) => {
        if (r) {
          r.items.forEach((element) => {
            console.log(element);

            this.list.push(element);
          });
        }
      });
    },
    beforeOpen(event) {
      const t = this;
      // Create IE + others compatible event handler
      const eventMethod = window.addEventListener
        ? "addEventListener"
        : "attachEvent";
      const eventer = window[eventMethod];
      const messageEvent =
        eventMethod == "attachEvent" ? "onmessage" : "message";

      t.$store
        .dispatch("GamesList", {
          page: 1,
          "per-page": this.perPage,
        })
        .then((r) => {
          console.log(r);
          this.list = r.items;
          this.pages = r._meta.pageCount;
        });

      // Listen to message from child window
      eventer(
        messageEvent,
        function (e) {
          console.log("parent received message!:  ", e.data);
          if (e.data === "closeTheGame") {
            t.$store.dispatch("GetProfile");
            t.closeModal("games_popup");
          }
        },
        false
      );
    },
  },
};
</script>
