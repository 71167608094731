<template>
  <div class="form-select" :class="size ? 'form-select--' + size : ''">
    <div class="form__caption" v-if="labelText">{{ labelText }}</div>
    <v-select
      :searchable="false"
      :options="options"
      v-model.trim="retValue"
      :name="name"
      :reduce="(label) => label.id"
      :placeholder="placeholder"
      @focus="errorReset()"
      @change="onInput()"
      @input="onInput()"
      :class="className"
    >
      <span slot="no-options">Ничего не найдено</span>
    </v-select>

    <div class="error-hint" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      retValue: this.field,
    };
  },

  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },
  },
  props: [
    "options",
    "field",
    "name",
    "error",
    "labelText",
    "className",
    "reduceVale",
    "placeholder",
    "size",
  ],
};
</script>

<style lang="scss">
$height: $field-height-lg;
$height-sm: $field-height-sm;
$color: $black;
$font_size: $field-font-size-lg;
$background: #ffffff;
$border: $field-border;
$align: left;
$radius-sm: $field-border-radius-sm;
$radius: $field-border-radius-lg;

.form-select {
  position: relative;
  max-width: 100%;
  z-index: 10;
  .v-select {
    height: $height-sm;
    margin: 0 auto rem(20px);
    z-index: 1;
    &._bordered {
      height: rem(55px);
      margin-bottom: 0;
      .vs__open-indicator {
        width: rem(14px);
        height: rem(20px);
        margin-left: rem(14px);
        background-image: url("../../assets/images/icons/select-arrow-2.svg");
        background-repeat: no-repeat;
        background-size: rem(14px);
      }
      .vs__selected {
        position: relative;
        color: #fff;
        font-family: $reg;
        font-size: rem(16px);
        text-transform: uppercase;
        text-align: left;
        padding: 0px 5px 0 0;
      }
      .vs__dropdown-toggle {
        padding: rem(0px) rem(26px) 0 rem(20px);
        border: rem(2px) solid #fff;
        border-radius: rem(30px);
        background: $brown;
      }
      .vs__dropdown-menu {
        width: 100%;
        min-width: auto;
        top: rem(50px);
        margin-top: 0;
        padding-top: rem(10px);
        border-radius: rem(16px);
        border: none;
        background: #ffffff;
        z-index: 2000;
      }
      .vs__dropdown-option {
        padding: rem(8px) rem(17px) rem(8px);
        color: $brown;
        font-family: $reg;
      }
      .vs__dropdown-option--highlight {
        color: $brown;
        background: #e4e4e4;
      }
    }
  }
  &._rounded {
    .v-select {
      .vs__dropdown-toggle,
      .vs__dropdown-menu {
        border-radius: rem(30px);
      }
    }
  }
  &.error {
    .v-select {
      .vs__dropdown-toggle {
        border-color: #ec2a86 !important;
      }
    }
  }
  &._no-border {
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      border: none;
    }
  }
  .vs__dropdown-toggle {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1;
    height: 100%;
    max-width: 100%;
    text-transform: uppercase;
    z-index: 1001;
    background: $background;
    border-radius: $radius-sm !important;
    border: $border;
    padding: $field-padding-sm;
  }
  .vs--open {
    .vs__dropdown-toggle {
      border-bottom-width: 0px !important;
    }
  }
  .vs__selected-options {
    padding: 0;
    max-width: 90%;
  }

  .vs__search {
    padding: 0;
    margin: 0;
    border: 0;
    height: 100%;
    font-family: $medium;
    font-size: $field-font-size-sm;
    color: $color;
    &::placeholder {
      color: $field-placeholder;
      text-transform: uppercase;
      font-size: $field-font-size-sm;
    }
    &:focus {
      padding: 0;
      margin: 0;
      border: 0;
    }
  }

  .vs__actions {
    margin-left: auto;
    padding: 0px 0px 0 3px;
  }

  .vs__clear {
    display: none;
  }

  .vs__open-indicator {
    width: rem(30px);
    height: rem(30px);
    background: url("../../assets/images/icons/select-arrow-2.svg") no-repeat
      center;
    background-size: rem(15px);
    path {
      display: none;
    }
  }

  .vs__selected {
    width: 100%;
    display: block;
    bottom: 0;
    padding: 0;
    margin: 0;
    color: $color;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: $align;
    font-family: $medium;
    font-size: $field-font-size-sm;
  }

  .vs--single.vs--open .vs__selected {
    position: relative;
    opacity: 1;
  }

  .vs__dropdown-menu {
    border: 0;
    max-height: rem(400px);
    margin-top: rem(5px);
    padding-top: 0;
    background: $background;
    border: none;
    border-radius: rem(16px);
  }

  .vs__dropdown-option {
    position: relative;
    max-width: 100%;
    white-space: normal;
    text-transform: uppercase;
    color: $color;
    font-size: $field-font-size-sm;
    text-align: $align;
    font-family: $medium;
    padding: $field-padding-sm;
  }

  .vs__dropdown-option--highlight {
    color: $color;
    background: $orange;
  }
  @media (min-width: $md) and (orientation: landscape) {
    margin: 0;
    .v-select {
      height: $height;
      &._bordered {
        height: rem(60px);
        .vs__selected {
          text-align: center;
          font-size: rem(20px);
        }
        .vs__dropdown-toggle {
          padding: rem(0px) rem(26px) 0 rem(28px);
        }
      }
    }
    .vs__selected,
    .vs__search,
    .vs__search::placeholder,
    .vs__dropdown-option {
      font-size: $field-font-size-lg !important;
    }
    .vs__dropdown-toggle {
      padding: $field-select-padding-lg;
      border: rem(2px) solid $border;
      border-radius: $radius !important;
    }
    .vs__dropdown-menu {
      margin-top: rem(5px);
      padding: rem(16px) 0;
      border-radius: $radius;
    }
    .vs__dropdown-option {
      padding: $field-select-padding-lg;
    }
    .vs__open-indicator {
      width: rem(20px);
      height: rem(36px);
    }
    &.form-select--lg {
      .v-select {
        height: rem(70px);
      }
    }
  }
}
</style>
