<template>
  <label class="form-checkbox" :class="color ? `_${color}` : ''">
    <input
      type="checkbox"
      class="form-checkbox__input"
      @change="onInput()"
      v-model.trim="retValue"
    />
    <div class="form-checkbox__custom"></div>
    <div class="form-checkbox__caption" v-html="label"></div>
  </label>
</template>

<script>
export default {
  data() {
    return {
      retValue: this.field,
    };
  },

  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },
  },
  props: {
    field: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    color: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-checkbox {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  &:not(:last-of-type) {
    margin-bottom: rem(16px);
  }
  &._center {
    align-items: center;
  }

  &__input {
    position: absolute;
    left: -9999px;
    opacity: 0;
    visibility: hidden;
  }

  &__input:checked + .form-checkbox__custom {
    background-color: $orange;
    background-image: url("../../assets/images/icons/checked.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
  }

  &__custom {
    width: rem(24px);
    height: rem(24px);
    flex-shrink: 0;
    background-size: rem(18px);
    border: rem(2px) solid $orange;
    background: transparent;
    border-radius: rem(8px);
    background-repeat: no-repeat;
    background-position: center;
    ._white & {
      background: #fff;
      border: none;
    }
    // transition: all 0.3s ease-in-out;
  }

  &__caption {
    margin-top: rem(2px);
    margin-left: rem(14px);
    font-size: rem(14px);
    line-height: 1.3;
    color: $white;
    a {
      color: $yellow;
      font-family: $bold;
      border-bottom: 1px solid $yellow;
    }
  }
  @media (orientation: landscape) {
    &__custom {
      @include size(rem(24px));
      background-size: rem(14px);
    }
    &__caption {
      margin-top: rem(2px);
      margin-left: rem(12px);
      font-size: rem(16px);
      line-height: 1.2;
    }
  }
}

.error .form-checkbox__custom {
  background: #ff9999;
}
</style>
