<template>
  <section class="steps section" id="steps">
    <div class="container">
      <div class="steps__content">
        <h2 class="section-title">Как выиграть?</h2>
        <div class="steps__list">
          <div class="steps__item" v-for="(item, idx) in steps" :key="idx">
            <div class="steps__item-img-wrapper">
              <img
                :src="require('../assets/images/steps/' + item.img)"
                alt=""
                class="steps__item-img"
              />
            </div>
            <div class="steps__item-content">
              <img
                :src="require('../assets/images/steps/' + item.index)"
                alt=""
                class="steps__item-index"
              />
              <div class="steps__item-title" v-html="item.title"></div>
              <div class="steps__item-descr" v-html="item.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      steps: [
        {
          img: "step_1.png",
          index: "step_1-1.png",
          title: "Покупай",
          text: "Jaguar Extra Urban Energy В&nbsp;пятёрочке",
        },
        {
          img: "step_2.png",
          index: "step_2-1.png",
          title: "Регистрируй",
          text: `перейди по&nbsp;qr-коду на&nbsp;банке. зарегистрируй чек на&nbsp;сайте <br>или в&nbsp;<a href='https://t.me/jaguar_energy_promo_bot${
            this.$store.getters.user?.promocode
              ? `?start=${this.$store.getters.user?.promocode}`
              : localStorage.getItem("click_id")
              ? `?start=separator${localStorage.getItem("click_id")}`
              : ""
          }' target='_blank' class='_undeline-link'>чат боте</a>`,
        },
        {
          img: "step_3.png",
          index: "step_3-1.png",
          title: "Получи",
          text: "приз&nbsp;&mdash; 400 баллов на&nbsp;карту лояльности &laquo;Х5&nbsp;клуба&raquo; <br>за&nbsp;свой первый чек",
        },
        {
          img: "step_4.png",
          index: "step_4-1.png",
          title: "выигрывай",
          text: "в&nbsp;розыгрыше еженедельных призов и&nbsp;главного приза. больше банок&nbsp;&mdash; больше шансов",
        },
      ],
    };
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.steps {
  position: relative;
  display: flex;
  padding-top: rem(65px);
  padding-bottom: rem(0px);
  .container {
    position: relative;
    z-index: 99;
  }
  &__content {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    z-index: 9;
  }
  .section-title {
    margin-bottom: rem(24px);
  }
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    margin-bottom: rem(8px);
    padding: 0 rem(50px);
    &-img {
      height: 100%;
      &-wrapper {
        max-width: 100%;
        height: rem(202px);
        margin-bottom: rem(8px);
        margin-right: 0;
      }
    }
    .btn {
      margin: rem(22px) auto 0;
    }
    &-index {
      @include size(rem(40px));
      margin: rem(-56px) auto rem(16px);
    }
    &-small {
      min-height: rem(18px);
      margin-bottom: rem(5px);
      margin-left: rem(45px);
      text-align: left;
      font-size: rem(15px);
      line-height: rem(18px);
    }
    &-title {
      margin-bottom: rem(6px);
      font-size: rem(28px);
      line-height: rem(32px);
      font-family: $bold;
      color: $orange;
      text-transform: uppercase;
    }
    &-descr {
      margin: auto;
      text-transform: uppercase;
      font-size: rem(16px);
      line-height: rem(20px);
    }
    &:not(:last-of-type) {
    }
  }
  @media (orientation: landscape) {
    padding-top: rem(94px);
    padding-bottom: rem(40px);
    .section-title {
      margin-bottom: rem(30px);
    }
    &__content {
    }
    &__list {
      display: flex;
      width: 88%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: 0 rem(30px);
    }

    &__item {
      flex: 0 0 24%;
      flex-direction: column;
      margin-bottom: 0;
      padding: 0;
      &-img {
        &-wrapper {
          max-width: 100%;
          height: rem(264px);
          margin-bottom: rem(18px);
          margin-right: 0;
        }
      }
      .btn {
        margin: rem(22px) auto 0;
      }
      &-index {
        @include size(rem(56px));
        margin: rem(-56px) auto rem(16px);
      }
      &-small {
        min-height: rem(18px);
        margin-bottom: rem(5px);
        margin-left: rem(45px);
        text-align: left;
        font-size: rem(15px);
        line-height: rem(18px);
      }
      &-title {
        margin-bottom: rem(6px);
        font-size: rem(36px);
        line-height: rem(43px);
      }
      &-descr {
        margin: auto;
        font-size: rem(16px);
        line-height: rem(24px);
      }
      &:not(:last-of-type) {
      }
    }
  }
  @media (orientation: landscape) {
    .container {
      display: flex;
      align-items: center;
    }
    &__list {
      margin: auto;
      margin-top: 0;
      padding-bottom: 0;
    }
  }
}
@keyframes umbrella {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
