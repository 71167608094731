<template>
  <div class="table_holder w-100">
    <div class="table--desktop table">
      <div class="table__header">
        <div class="table__header-row">
          <div
            class="table__header-col"
            v-for="(item, index) in thead"
            :key="index"
            :style="{ width: item.size }"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="table__inner">
        <table>
          <tbody>
            <slot name="tbody"></slot>
          </tbody>
        </table>
      </div>
    </div>
    <div class="table--mobile table">
      <div class="table__inner">
        <slot name="mobile"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["thead"],
  data: () => ({}),
};
</script>

<style lang="scss">
.table_holder {
  td.status-bad {
    color: #912982;
  }
  td.status-moderation {
    color: #fff;
  }
  .error_icon {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: rem(17px);
    height: rem(17px);
    font-size: rem(12px);
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(1, 63, 93, 0.15);
    border-radius: 50%;
  }
}
.table {
  width: 100%;
  margin: 0 auto;
  font-size: rem(16px);
  &-info-trigger {
    display: block;
    margin-left: rem(8px);
  }
  &__header {
    width: 96%;
    margin-bottom: rem(10px);
    &-row {
      display: flex;
      border-bottom: rem(2px) solid rgba(0, 0, 0, 0.5);
    }
    &-col {
      position: relative;
      padding: 0 rem(25px) rem(15px);
      color: $white;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: left;
      font-family: $bold;
      text-transform: uppercase;
      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: rem(0px);
        left: 0;
        width: 100%;
        height: rem(2px);
        background: $white;
        pointer-events: none;
        z-index: -1;
      }
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
  &__show-img {
    width: rem(21px);
    margin-left: rem(8px);
    cursor: pointer;
  }
  &--desktop {
    display: none !important;
    tr {
      margin-bottom: rem(10px);
      vertical-align: middle;
      &:not(:last-child) {
        td {
          border-bottom: 1px solid #262626;
        }
      }
    }
    td {
      position: relative;
      padding: rem(15px) rem(25px) rem(15px);
      color: $white;
      font-size: rem(14px);
      line-height: 1;
      text-transform: uppercase;
      font-family: $reg;
      overflow: hidden;
      img {
        width: rem(52px);
        margin-right: rem(18px);
        cursor: pointer;
        &.prize-img {
          width: rem(40px);
          cursor: default;
        }
      }
      small {
        display: block;
        font-size: rem(12px);
        text-transform: none;
      }
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }

    tbody {
      tr {
        path {
        }
        &._not-accepted {
          color: #f90025;
          path {
            fill: #f90025;
          }
        }
        &._accepted {
          color: #000;
          path {
            fill: #000;
          }
        }
        &._received {
          color: #209524;
          path {
            fill: #209524;
          }
        }
      }
    }
    .table-phone {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-size: rem(20px);
      input {
        font-size: rem(20px);
      }
      svg {
        flex-shrink: 0;
        width: rem(24px);
        margin-right: rem(9px);
      }
    }
  }
  &__inner {
    width: 100%;
    max-height: rem(375px);
    min-height: rem(250px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: rem(8px);
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #b36900;
      border-radius: 25px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $orange;
      border-radius: 10px;
      transition: all 0.3s ease-in-out;
    }
  }
  table {
    position: relative;
    width: 98%;
    text-align: left;
    z-index: 1;
  }

  &--mobile {
    position: relative;
    display: block;
    width: 100%;
    .table__inner {
      width: calc(100% + rem(10px));
      max-height: rem(520px);
      padding-right: rem(10px);
      &::-webkit-scrollbar {
        width: rem(4px);
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: rgba(#b36900, 0.3);
        border-radius: 25px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $orange;
        border-radius: 30px;
        transition: all 0.3s ease-in-out;
      }
      & > table {
        & > tr {
          &:last-child {
            & > td {
              border-bottom: none;
              text-align: right;
            }
          }
        }
      }
    }
    .table-mobile__inner {
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      tr {
        vertical-align: middle;
        &:first-of-type {
          td {
            padding-top: rem(16px);
          }
        }
        &:last-of-type {
          td {
            padding-bottom: rem(16px);
          }
        }
      }
      td {
        padding: rem(4px) 0;
        text-align: left;
        text-transform: uppercase;
        font-size: rem(14px);
        line-height: 1;
        font-family: $medium;
        &:first-child {
          color: rgba(255, 255, 255, 0.5);
        }
        &:last-child {
          width: 80%;
          text-align: right;
          .d-flex {
            justify-content: flex-end;
          }
        }
        small {
          display: block;
          text-transform: none;
          font-size: rem(11px);
        }
        img {
          width: rem(50px);
        }
        .table-phone {
          display: flex;
          align-items: flex-start;
          margin-top: rem(5px);
          svg {
            flex-shrink: 0;
            width: rem(22px);
            margin-right: rem(9px);
          }
          input {
            font-size: rem(16px);
            text-align: left;
          }
        }
      }
    }
  }
  @media (orientation: landscape) {
    &--desktop {
      display: block !important;
    }
    &--mobile {
      display: none !important;
    }
    &__inner {
      max-height: rem(365px);
      padding-right: rem(23px);
      &::-webkit-scrollbar {
        width: rem(8px);
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: rgba(#b36900, 0.3);
        border-radius: 25px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $orange;
        border-radius: 30px;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
</style>
