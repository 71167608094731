var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitSendCode()}}},[_c('ul',{staticClass:"modal-nav"},[_c('li',{staticClass:"modal-nav__item _pointer",on:{"click":function($event){return _vm.showLogInModal()}}},[_vm._v("Вход")]),_c('li',{staticClass:"modal-nav__item active"},[_vm._v("Регистрация")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],attrs:{"type":"hidden","name":"phone"},domProps:{"value":(_vm.phoneNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.phoneNumber=$event.target.value}}}),_c('div',{staticClass:"modal__subtitle"},[_vm._v(" Введи код, который отправили вам на почту ")]),_c('div',{staticClass:"form-wrapper-small"},[_c('inputText',{staticClass:"_centered-text",class:{
          error:
            _vm.validationStatus(_vm.$v.code) ||
            _vm.errorMessage.phoneNumber ||
            _vm.errorMessage.code,
        },attrs:{"field":_vm.code,"placeholder":"•••••","error":(!_vm.$v.code.required && _vm.$v.code.$error
            ? 'Обязательное поле'
            : !_vm.$v.code.minLength && _vm.$v.code.$error
            ? 'Минимум ' + _vm.$v.code.$params.minLength.min + ' символов'
            : !_vm.$v.code.maxLength && _vm.$v.code.$error
            ? 'Максимум ' + _vm.$v.code.$params.maxLength.max + ' символов'
            : _vm.errorMessage.code) || _vm.errorMessage.phoneNumber},on:{"input":function($event){_vm.code = $event}}}),(!_vm.showNewCode)?_c('div',{staticClass:"form__get-new-code"},[_vm._v(" получить новый код "),_vm._m(0)]):_c('div',{staticClass:"form__get-new-code _yellow _undeline-link",on:{"click":function($event){return _vm.resendCode()}}},[_vm._v(" Получить новый код ")]),_c('div',{staticClass:"form__footer"},[_c('button',{staticClass:"btn btn--yellow",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"submit"}},[_vm._v(" зарегистрироваться ")])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("00:"),_c('span',{attrs:{"id":"countdowntimer"}},[_vm._v("45")])])
}]

export { render, staticRenderFns }