<template>
  <Modal
    name="new-password"
    size="md"
    title="Смена пароля"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <new-password-form />
    </template>
  </Modal>
</template>

<script>
import NewPasswordForm from "../form/NewPasswordForm.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
    NewPasswordForm,
  },
  data: function () {
    return {};
  },

  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>

<style lang="scss" scoped></style>
