var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"update-info"},[_c('inputText',{class:{ error: _vm.validationStatus(_vm.$v.name) || _vm.errorMessage.name },attrs:{"placeholder":"Ваше имя","field":_vm.name,"error":(!_vm.$v.name.required && _vm.$v.name.$error
        ? 'Обязательное поле'
        : !_vm.$v.name.rus && _vm.$v.name.$error
        ? 'Введи текст на русском языке'
        : '') || _vm.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('inputText',{class:{ error: _vm.validationStatus(_vm.$v.surname) || _vm.errorMessage.surname },attrs:{"placeholder":"Фамилия","field":_vm.surname,"error":(!_vm.$v.surname.required && _vm.$v.name.$error
        ? 'Обязательное поле'
        : !_vm.$v.surname.rus && _vm.$v.surname.$error
        ? 'Введи текст на русском языке'
        : '') || _vm.errorMessage.surname},on:{"input":function($event){_vm.surname = $event}}}),_c('label',{staticClass:"form__field form__field--white",class:{
      error: _vm.validationStatus(_vm.$v.city) || this.errorMessage.city,
    }},[_c('dadata-suggestions',{key:_vm.indexCity,staticClass:"form__field-input form__field-input--lg",attrs:{"id":"city","placeholder":"Город","field-value":"value","options":_vm.dadataOptionsCity},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),(_vm.errorMessage.city || _vm.$v.city.$error)?_c('div',{staticClass:"error-hint"},[_vm._v(" "+_vm._s((!_vm.$v.city.required && _vm.$v.city.$error ? "Обязательное поле" : "") || _vm.errorMessage.city)+" ")]):_vm._e()],1),_c('div',{staticClass:"recover__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Отправить"),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }