<template>
  <div id=""></div>
</template>

<script>
export default {
  name: "Apmcheck",

  data() {
    return {
      ready: false,
    };
  },

  methods: {},
  // created() {},
  mounted() {
    this.ready = true;
  },

  components: {},
};
</script>

<style lang="scss">
#promo-tools {
  .popup {
    display: flex;
    align-items: flex-start;
    padding-top: rem(112px);
    padding-bottom: rem(50px);
    background: rgba($black, 0.8);
    backdrop-filter: blur(6px);
    &::-webkit-scrollbar {
      width: rem(5px);
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #ececec;
      border-radius: 13px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $grey;
      transition: all 0.3s ease-in-out;
    }
    &__container {
      width: calc(100% - rem(32px));
      padding: rem(64px) rem(32px);
      height: auto;
      background: $black;
      border: rem(2px) solid $orange;
      border-radius: rem(36px);
      box-shadow: 0px 0px 72px 0px rgba(255, 149, 0, 1);
    }
    .check-grabber {
      color: $white;
      &__fail-icon {
        display: none;
        @include size(#{rem(75px)});
        font-size: 0;
        background: url("../assets/images/icons/error.svg") no-repeat center;
        background-size: contain;
      }
      &__title {
        margin-bottom: rem(8px);
        font-size: rem(30px);
        text-transform: uppercase;
        line-height: 1;
        color: $white;
        font-family: $medium;
        text-align: center;
      }
      &__sub-title {
        margin-top: 0;
        font-size: rem(14px);
        line-height: rem(22px);
        font-family: $reg;
        text-transform: uppercase;
        strong {
          display: block;
          margin-bottom: rem(16px);
          font-family: $bold;
          font-size: rem(24px);
          line-height: 1;
          font-weight: normal;
          text-transform: uppercase;
          opacity: 1;
        }
        &:empty {
          display: none;
        }
      }
      &__form {
        margin-top: rem(20px);
        grid-row-gap: $field-offset-sm;
      }
      &__photos {
        width: 100%;
        display: flex;
        gap: 0 rem(8px);
        margin-top: rem(20px);
        background: url("../assets/images/icons/borders.svg") no-repeat center
          top;
        background-size: 100%;
      }
      &__photo {
        height: 100%;
        border: none;
        &::after {
          content: "";
          display: inline-block;
          width: 0;
          padding-top: 100%;
        }
        &-item {
          flex: 0 1 18%;
          height: rem(57px);
        }
        img {
          object-fit: cover;
          border-radius: rem(10px);
          border: 1px solid rgba($brown, 0.4);
        }
        &-x {
          top: rem(4px);
          right: rem(4px);
          font-size: rem(8px);
          border-radius: 50%;
          background: $orange;
          transform: none !important;
        }
      }
      &__btn-set {
        grid-row-gap: rem(16px);
        margin-top: rem(32px);
        .uploader {
          margin-bottom: rem(10px);
        }
      }
      &__btn-back {
        color: $red;
        &-icon {
          background: $red;
        }
      }
      &__hint {
        color: $red;
        font-family: $reg;
      }
      .btn {
        font-size: rem(16px);
      }
    }
    &__close {
      font-size: 0;
      cursor: pointer;
      display: block;
      position: absolute;
      top: rem(16px);
      right: rem(16px);
      padding: 0;
      @include size(rem(40px));
      background: rgba(255, 255, 255, 0.15)
        url("../assets/images/icons/close.svg") no-repeat center;
      background-size: rem(13px);
      border-radius: 50%;
      transition: 0.4s;
      &:hover {
        transform: rotate(90deg);
      }
    }
    .btn {
      font-family: $bold;
      position: relative;
      padding: rem(10px) rem(20px);
      border: rem(2px) solid currentColor;
      border-radius: rem(30px);
      box-shadow: none;
      text-transform: uppercase;
      z-index: 1;
      color: $black;
      background: $orange;
      &:hover {
        opacity: 1;
      }
      &.-red {
        background: $orange;
        border-color: $orange;
        color: $black;
        &:hover {
          background: $yellow;
          border-color: $yellow;
        }
      }
      &.-grey {
        background: transparent;
        border-color: $orange;
        color: $orange;
        &:hover {
          color: $black;
          background: $orange;
          border-color: $orange;
        }
      }
    }
    .field {
      position: relative;
      &__label {
        margin-bottom: rem(5px);
        font-size: $field-font-size-sm;
        line-height: 1;
        color: $field-label-color;
        font-family: $reg;
        text-transform: uppercase;
        transition: 0.4s;
        z-index: 1;
        &-hint {
          font-family: $reg;
          color: $field-label-color;
        }
      }
      &__input {
        width: 100%;
        height: $field-height-sm;
        padding: $field-padding-checks-lg;
        font-size: $field-font-size-sm;
        max-width: 100%;
        appearance: none;
        font-family: $medium;
        background: #ffffff;
        border-radius: $field-border-radius-sm;
        text-transform: none;
        transition: all 0.3s ease-in-out;
        border: $field-border;
        color: $field-color;
        &::placeholder {
          color: $field-placeholder;
          font-family: $medium;
        }
      }
      &__error {
        color: $red;
        font-family: $reg;
        font-size: rem(12px);
        line-height: 1;
        margin-top: rem(2px);
      }
      &__icon {
        @include size(#{rem(22px)});
        font-size: 0;
        background: url("../assets/images/icons/success.svg");
        background-size: contain;
        &.-error {
          background: url("../assets/images/icons/error-small.svg");
          background-size: contain;
        }
      }
      .dp__input_icon {
        display: none;
      }
    }
    ._spinner_n2u70_7:after {
      border: none !important;
      background: url("../assets/images/icons/spinner.svg") no-repeat center;
      background-size: contain;
    }
    .uploader {
      position: relative;
      margin-top: rem(32px);
      &.mt-0 {
        margin-top: 0 !important;
      }
      // &::before {
      //   content: "";
      //   display: block;
      //   width: 100%;
      //   height: rem(196px);

      //   background: rgba($grey, 0.2)
      //     url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      //   border-radius: 20px;
      // }
      // &::after {
      //   content: "Перетащите фото чека сюда или загрузите с компьютера";
      //   position: absolute;
      //   top: rem(20px);
      //   left: 50%;
      //   transform: translateX(-50%);
      //   display: block;
      //   width: 70%;
      //   margin: 0 auto rem(12px);
      //   padding-top: rem(52px);
      //   background: url("../assets/images/icons/camera.svg") no-repeat center
      //     top;
      //   background-size: rem(47px);
      //   font-size: rem(18px);
      //   text-transform: uppercase;
      //   line-height: 1.1;
      //   font-family: $reg;
      // }
      // .btn {
      //   position: absolute;
      //   left: 50%;
      //   top: rem(130px);
      //   transform: translateX(-50%);
      //   width: 80%;
      //   margin-bottom: rem(25px);
      //   color: #fff;
      // }
    }
    .check-info {
      display: inline-block;
      vertical-align: middle;
      margin-left: rem(3px);
      cursor: pointer;
    }
    @media (orientation: landscape) {
      padding-top: vh(100px);
      &__container {
        width: rem(560px);
        padding: rem(56px);
        border-radius: rem(36px);
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 20%;
          transform: translateX(50%);
          display: block;
          @include size(rem(350px), rem(290px));
          // background: url("../assets/images/check.png") no-repeat center;
          background-size: contain;
        }
      }

      .check-grabber {
        text-align: center;
        &__fail-icon {
          @include size(#{rem(75px)});
        }
        &__title {
          margin-bottom: rem(8px);
          font-size: rem(32px);
          line-height: 0.9;
        }
        &__sub-title {
          margin-top: rem(16px);
          font-size: rem(16px);
          line-height: 1.2;
          strong {
            margin-bottom: rem(10px);
            font-size: rem(50px);
            line-height: 1;
          }
        }
        &__form {
          margin-top: rem(30px);
          grid-row-gap: $field-offset-lg;
        }
        &__btn-set {
          margin-top: rem(16px);
          .uploader {
            margin-bottom: 0;
          }
        }
        &__scanner {
          width: 100%;
          border-radius: rem(20px);
          background: none;
          &-item {
            top: 0;
            left: 0;
            @include size(100% !important);
            border-radius: rem(20px);
          }
        }
        &__photos {
          width: 100%;
          margin-top: rem(30px);
          gap: rem(11.5px);
          margin-left: 0;
        }
        &__photo {
          &-item {
            flex: 0 0 18%;
            height: rem(82px);
            &-x {
              top: rem(4px);
              right: rem(4px);
              font-size: rem(12px);
            }
          }
        }
      }
      .uploader {
        // &::before {
        //   height: rem(220px);
        // }
        // &::after {
        //   top: rem(30px);
        //   width: 55%;
        //   margin: 0 auto rem(21px);
        //   padding-top: rem(62px);
        //   font-size: rem(16px);
        //   line-height: 1.1;
        //   background-size: rem(58px);
        // }
        // .btn {
        //   width: 60%;
        //   top: rem(140px);
        // }
      }
      .btn {
        width: rem(267px);
        padding: rem(25px);
        margin-right: auto;
        margin-left: auto;
        font-size: rem(16px);
        border-radius: rem(50px);
      }
      .field {
        width: 100%;
        &__label {
          top: rem(18px);
          left: $field-offset-lg;
          font-size: $field-font-size-lg;
          &-hint {
            font-size: rem(14px);
          }
        }
        &__input {
          height: $field-height-lg;
          padding: $field-padding-checks-lg;
          font-size: $field-font-size-lg;
          border-radius: $field-border-radius-lg;
        }
        &__error {
          font-size: rem(12px);
        }
      }
    }
  }
}
</style>
