var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"recover"},[_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.oldPassword) || _vm.errorMessage.oldPassword,
    },attrs:{"label":"старый пароль","type":"password","placeholder":"••••••","maxlength":"24","field":_vm.oldPassword,"error":(!_vm.$v.oldPassword.required && _vm.$v.oldPassword.$error
        ? 'Обязательное поле'
        : !_vm.$v.oldPassword.minLength && _vm.$v.oldPassword.$error
        ? 'Минимум 6 символа'
        : !_vm.$v.oldPassword.maxLength && _vm.$v.oldPassword.$error
        ? 'Максимум 24 символа'
        : '') || _vm.errorMessage.oldPassword},on:{"input":function($event){_vm.oldPassword = $event}}}),_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.newPassword) || _vm.errorMessage.newPassword,
    },attrs:{"label":"новый пароль","type":"password","maxlength":"24","placeholder":"••••••","field":_vm.newPassword,"error":(!_vm.$v.newPassword.required && _vm.$v.newPassword.$error
        ? 'Обязательное поле'
        : !_vm.$v.newPassword.minLength && _vm.$v.newPassword.$error
        ? 'Минимум 6 символов'
        : !_vm.$v.newPassword.maxLength && _vm.$v.newPassword.$error
        ? 'Максимум 8 символа'
        : '') || _vm.errorMessage.newPassword},on:{"input":function($event){_vm.newPassword = $event}}}),_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.repPassword) || _vm.errorMessage.repPassword,
    },attrs:{"label":"повтори пароль","type":"password","maxlength":"24","placeholder":"••••••","field":_vm.repPassword,"error":(!_vm.$v.repPassword.required && _vm.$v.repPassword.$error
        ? 'Обязательное поле'
        : !_vm.$v.repPassword.minLength && _vm.$v.repPassword.$error
        ? 'Минимум 6 символов'
        : !_vm.$v.repPassword.maxLength && _vm.$v.repPassword.$error
        ? 'Максимум 24 символа'
        : '') || _vm.errorMessage.repPassword},on:{"input":function($event){_vm.repPassword = $event}}}),_c('div',{staticClass:"recover__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Отправить"),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }