var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"form"},[_c('ul',{staticClass:"modal-nav"},[_c('li',{staticClass:"modal-nav__item active"},[_vm._v("Вход")]),_c('li',{staticClass:"modal-nav__item _pointer",on:{"click":function($event){return _vm.showSignUpModal()}}},[_vm._v(" Регистрация ")])]),_c('div',{staticClass:"form__fields"},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.email) || _vm.errorMessage.email,
      },attrs:{"label":"E-mail","type":"email","field":_vm.email,"error":(!_vm.$v.email.required && _vm.$v.email.$error
          ? 'Обязательное поле'
          : !_vm.$v.email.email && _vm.$v.email.$error
          ? 'Некорректный e-mail'
          : '') || _vm.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.password) || this.errorMessage.password,
      },attrs:{"label":"Пароль","type":"password","field":_vm.password,"error":(!_vm.$v.password.required && _vm.$v.password.$error
          ? 'Обязательное поле'
          : !_vm.$v.password.minLength && _vm.$v.password.$error
          ? 'Минимум ' + _vm.$v.password.$params.minLength.min + ' символов'
          : '') || this.errorMessage.password},on:{"input":function($event){_vm.password = $event}}}),_c('div',{staticClass:"form__row"},[_c('checkBox',{staticClass:"_uppercase",attrs:{"label":"Запомнить меня","field":_vm.checkbox},on:{"input":function($event){_vm.checkbox = $event}}}),_c('div',{staticClass:"form__forget-link _undeline-link _yellow",on:{"click":function($event){$event.preventDefault();return _vm.switchToRecover()}}},[_vm._v(" ВОССТАНОВИТЬ ПАРОЛЬ ")])],1),_c('div',{staticClass:"form__footer"},[_c('button',{staticClass:"btn btn--yellow btn--icon",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_c('span',[_vm._v("Войти")]),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}})]),_c('div',{staticClass:"modal__another-option"},[_c('span',[_vm._v("нет аккаунта? ")]),_c('span',{staticClass:"_undeline-link _yellow",on:{"click":_vm.showSignUpModal}},[_vm._v(" зарегистрироваться ")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }