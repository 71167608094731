var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"name":"uploading_options","size":"md","title":"зарегистрировать чек"},on:{"closeModal":_vm.closeModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"modal__buttons"},[_c('button',{staticClass:"btn btn--yellow",on:{"click":function($event){_vm.closeModal('uploading_options'),
            _vm.toCheckUpload(),
            _vm.setWeborama(11),
            _vm.setMetrika('main_check_site')}}},[_vm._v(" На сайте ")]),_c('button',{staticClass:"btn btn--bordered _yellow",on:{"click":function($event){_vm.closeModal('uploading_options'),
            _vm.openInNewTab(),
            _vm.setWeborama(12),
            _vm.setMetrika('main_check_bot')}}},[_vm._v(" В чат-боте ")])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }