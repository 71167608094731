<template>
  <Modal name="to_form" size="md" title="Поздравляем!" @closeModal="closeModal">
    <template v-slot:content>
      <div class="modal__text">
        Вы&nbsp;стали претендентом на&nbsp;еженедельный приз
        <br />промо-активации &laquo;Jaguar. За&nbsp;тобой
        не&nbsp;угнаться!&raquo;. <br />Заполните, пожалуйста, Анкету победителя
        для получения приза
      </div>
      <br />
      <div class="modal__buttons">
        <router-link
          :to="{ name: 'Form' }"
          class="btn btn--yellow"
          @click.native="closeModal('to_form')"
        >
          Заполнить
        </router-link>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      text: "",
      title: "Спасибо!",
      successIcon: false,
      btnClose: false,
      checkButton: false,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {},
  },
};
</script>
<style lang="scss">
.success_modal-btn {
  margin-top: rem(30px);
}
</style>
