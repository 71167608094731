import Vue from "vue";
import App from "./App.vue";
import "jquery";

import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/styles/main.scss";
import VModal from "vue-js-modal";

import typograf from "./utils/filters";
import VueScrollactive from "vue-scrollactive";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VTooltip from "v-tooltip";
import VueSmoothScroll from "vue2-smooth-scroll";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";
import LoadScript from "vue-plugin-load-script";
import DadataSuggestions from "vue-dadata-suggestions";
import VueMeta from "vue-meta";
import VueResource from "vue-resource";
import VueYandexMetrika from "vue-yandex-metrika";

Vue.use(VueResource);

Vue.use(VueMeta);
Vue.use(DadataSuggestions, {
  token: "e3468f8470bd9bff626cc9a80dfc9cceb5d01020",
  type: "ADDRESS",
});
Vue.use(VueYandexMetrika, {
  id: 98062592,
  router: router,
  env: "production",
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  debug: true,
});
Vue.use(LoadScript);
Vue.use(VueScrollactive);
Vue.component("v-select", vSelect);
Vue.use(VTooltip);
Vue.filter("text", typograf);
Vue.use(VueSmoothScroll, {
  offset: -150,
  duration: 400,
  updateHistory: false,
});
Vue.use(VueTheMask);
Vue.use(VModal);
Vue.use(Vuelidate);
function initWidget() {
  const t = this;
  const BASE_API = `${process.env.VUE_APP_URL}`;
  let checkKey = store.getters.checksKey;

  if (window.promoTools)
    window.promoTools.checkGrabberWidget.settings = {
      skin: "default",
      events: {
        onSentSuccess(res) {
          checkKey++;
          store.dispatch("GetChecks");
          store.commit("SET_CHECKS_KEY", checkKey);
          store.dispatch("GetProfile");
          localStorage.setItem("checkUploaded", true);
          if (localStorage.getItem("onUse") == "manual") {
            setMetrika("lk_hands_send");
            setWeborama(8);
          } else if (localStorage.getItem("onUse") == "photo") {
            setMetrika("lk_pfoto_send");
            setWeborama(9);
          }
        },
        onSentError(res) {
          gtmEvent("", "check", "check_download_error", "qr");
        },
        onUseQr() {
          localStorage.setItem("onUse", "qr");
        },
        onUsePhotos() {
          setMetrika("lk_pfoto");
          setWeborama(7);
          localStorage.setItem("onUse", "photo");
        },
        onUseManual() {
          setMetrika("lk_hands");
          setWeborama(6);
          localStorage.setItem("onUse", "manual");
        },
      },
      api: BASE_API,
      method: "receipts/api/widgets/receipts",
      userUuid: store.getters?.user?.promocode,
      i18n: {
        qr: {
          title: "Сканирование",
          subTitle: "Наведи камеру на QR-код",
          btnQr: "Сканировать QR-код чека",
          btnManual: "Ввести данные вручную",
          btnPhotos: "Загрузить фото чека",
          cameraError: {
            title:
              "<strong>Ошибка</strong>Мы&nbsp;не&nbsp;можем получить доступ к&nbsp;камере устройства. <br>Разреши браузеру доступ к&nbsp;камере или Введи данные с&nbsp;чека вручную.",
          },
        },
        photos: {
          title: "Загрузка фото",
          subTitle: "Добавь фото чека",
          btnPhotos: "Отправить",
        },
        manual: {
          title: "Ручной ввод",
          subTitle: "Введи данные с чека",
          btnManual: "Отправить",
        },
        success: {
          title: "Спасибо ЗА ЧЕК",
          subTitle:
            "ТВОЙ ЧЕК ОБРАБАТЫВАЕТСЯ ДО&nbsp;5-ТИ РАБОЧИХ ДНЕЙ. <br class='hidden-xs'>УЗНАТЬ СТАТУС ЧЕКА МОЖНО В&nbsp;ЛИЧНОМ КАБИНЕТЕ.",
        },
        pending: {
          title: "Подожди!",
          subTitle: "Идет отправка чека",
        },
        fail: {
          title: "Не удалось <br>отправить чек!",
        },
      },
    };
}
function gtmEvent(
  label = " ",
  site_section = " ",
  action = "click",
  eventContext = null
) {
  window?.dataLayer?.push({
    event: "interaction",
    site_section,
    action,
    label,
    eventContext,
  });
}
function setMetrika(i) {
  window.ym(98062592, "reachGoal", i);
}
function setWeborama(i) {
  new Image().src = `https://wcm-ru.frontend.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=co&a.si=9126&a.cp=${i}&a.ct=d`;
}
Vue.config.productionTip = false;
Vue.mixin({
  methods: {
    gtmEvent: (label, action, category) => {
      window.dataLayer?.push({
        event: "gaEvent",
        eventCategory: category || "",
        eventAction: action || "",
        eventLabel: label || "",
      });
    },
    setMetrika(i) {
      if (window.ym) {
        window.ym(98062592, "reachGoal", i);
      }
    },
    setWeborama(i) {
      new Image().src = `https://wcm-ru.frontend.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=co&a.si=9126&a.cp=${i}&a.ct=d`;
    },
    rememberSoc(item) {
      localStorage.socialName = item;
    },
    toCheckUpload() {
      const t = this;
      if (this.$store.getters.promoFinished) {
        this.$modal.show("temporarily_unavailable");
      } else {
        initWidget();
        if (this.$store.getters.user) {
          window.promoTools.checkGrabberWidget.show();
          setTimeout(() => {
            let close = document.querySelector("#promo-tools .popup__close");
            close.addEventListener("click", function () {
              console.log(localStorage.getItem("checkUploaded"));

              if (localStorage.getItem("checkUploaded") === "true") {
                console.log("sdfg");

                t.$modal.show("common_success", {
                  title: "отлично!",
                  successIcon: true,
                  text: '<p class="_uppercase">ты&nbsp;загрузил чек и&nbsp;теперь можно принять участие в&nbsp;голосовании за&nbsp;любимого героя!</p><p class="_uppercase">именно твой голос может быть решающим при выборе машины для розыгрыша</p>',
                  toProfile: true,
                });
                localStorage.setItem("checkUploaded", false);
              }
            });
          }, 1000);
        } else {
          this.$modal.show("authorization");
        }
      }
    },
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
