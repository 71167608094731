<template>
  <section class="products section" id="products">
    <div class="container">
      <h2 class="section-title">
        продукты участники <span class="hidden-xs">акции</span>
      </h2>
      <img
        src="../assets/images/products/subtitle.png"
        alt=""
        class="products__subtitle"
      />
      <div class="products__row hidden-xs">
        <div class="products__block">
          <img
            src="../assets/images/products/product_1-big.png"
            alt=""
            class="products__block-img"
          />
          <div class="products__block-text">
            Jaguar Extra Urban Energy, <br />со вкусом маракуйя-апельсин
          </div>
        </div>
        <div class="products__block">
          <img
            src="../assets/images/products/product_2-big.png"
            alt=""
            class="products__block-img"
          />
          <div class="products__block-text">
            Jaguar.Extra Urban Energy, <br />со&nbsp;вкусом питахайя-гуава
          </div>
        </div>
      </div>
      <VueSlickCarousel
        ref="slider"
        v-bind="settings"
        class="products-list visible-xs"
      >
        <div class="products__block">
          <img
            src="../assets/images/products/product_1-big.png"
            alt=""
            class="products__block-img"
          />
          <div class="products__block-text">
            Jaguar Extra Urban Energy, <br />со вкусом маракуйя-апельсин
          </div>
        </div>
        <div class="products__block">
          <img
            src="../assets/images/products/product_2-big.png"
            alt=""
            class="products__block-img"
          />
          <div class="products__block-text">
            Jaguar.Extra Urban Energy, <br />со&nbsp;вкусом питахайя-гуава
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: { VueSlickCarousel },
  data: function () {
    return {
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 3,
        infinite: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              fade: false,
              arrows: true,
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.products {
  padding-top: rem(55px);
  @media (orientation: landscape) {
    padding-top: rem(88px);
  }
  &__subtitle {
    margin: rem(-10px) auto rem(0px);
    width: rem(290px);
    max-width: 100%;
    @media (orientation: landscape) {
      margin: rem(-100px) auto rem(-60px);
      width: rem(660px);
    }
  }
  &__row {
    display: flex;
    justify-content: space-around;
  }
  &__block {
    &-img {
      width: 100%;
      margin-bottom: rem(20px);
      @media (orientation: landscape) {
        height: rem(523px);
      }
    }
    &-text {
      text-align: center;
      text-transform: uppercase;
      font-size: rem(16px);
    }
  }
}
</style>
