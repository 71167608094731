<template>
  <Modal name="enter_code" size="md" @closeModal="closeModal">
    <template v-slot:content>
      <div class="success_modal">
        <EnterCodeForm :id="id" :formData="formData" />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import EnterCodeForm from "@/components/form/EnterCodeForm.vue";
export default {
  components: {
    Modal,
    EnterCodeForm,
  },
  data: function () {
    return {
      id: null,
      formData: null,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.id = event.params.id;
      this.formData = event.params.formData;
    },
  },
  mounted() {},
};
</script>
