<template>
  <div class="hero section" id="home">
    <div class="container">
      <div class="timer" v-show="!hideTimer">
        <div class="timer__title _yellow">
          ДО СЛЕДУЮЩЕГО <br />ежедневного РОЗЫГРЫША
        </div>
        <!-- <div class="timer-clock"></div> -->
        <div class="countdown" id="countdown"></div>
      </div>

      <div class="hero__date">
        <span>Акция завершена</span>
      </div>
      <div class="hero__suptitle">Участвуй в гонке за&nbsp;призами</div>
      <div class="hero__title">
        <img src="../assets/images/hero/title.png" alt="" />
      </div>

      <img
        src="../assets/images/products.png"
        alt=""
        class="hero__products hidden-xs"
      />
    </div>
    <img src="../assets/images/hero/hero-m.png" alt="" class="hero__img visible-xs">
  </div>
</template>

<script>
export default {
  components: {
    // Feedback,
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    showPopup: false,
    hideTimer: true,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    toGames() {
      if (this.$store.getters.user) {
        if (this.$store.getters.user?.free_game > 0) {
          this.$modal.show("games_popup");
        } else {
          this.$modal.show("common_error", {
            title: "Недостаточно попыток",
            text: "Для того чтобы сыграть, загрузите чек с&nbsp;продукцией, участвующей в&nbsp;акции или дождитесь проверки загруженного чека.",
          });
        }
      } else {
        this.$modal.show("signup");
      }
    },
  },
  mounted() {
    // this.toCheckUpload()
  },
};
</script>
<style lang="scss">
.hero {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: calc(var(--vhH, 1vh) * 18);
  padding-bottom: 0;
  flex-direction: column;
  z-index: 30 !important;

  .container {
    position: relative;
    height: 100%;
    color: #fff;
    z-index: 30;
  }

  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    img {
      height: rem(32px);
      object-fit: contain;
    }
  }
  &__suptitle {
    font-size: rem(20px);
    line-height: 0.7;
    text-align: center;
    text-transform: uppercase;
    font-family: $bold;
  }
  &__products {
    height: rem(220px);
    margin-left: rem(-15px);
    margin-bottom: rem(14px);
  }
  &-block {
    position: fixed;
    margin: auto;
    display: flex;
    width: calc(100% - #{rem(48px)});
    left: 50%;
    bottom: calc(var(--vhH, 1vh) * 5);
    transform: translateX(-50%);
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    .btn {
      width: 100%;
    }
    &__popup {
      position: absolute;
      right: 5%;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      max-width: 90%;
      width: rem(376px);
      height: rem(486px);
      padding: rem(30px);
      border: 1px solid $orange;
      border-radius: rem(18px);
      background: #000 url("../assets/images/hero/popup-bg.png") no-repeat
        center top;
      background-size: 90%;
      transition: 0.4s;
      transform: translateX(150%);
      transition-timing-function: cubic-bezier(0.415, 0.16, 0.425, 1.355);
      z-index: 1;
      &._show {
        transform: translateX(0%);
      }
      &-close {
        position: absolute;
        right: rem(10px);
        top: rem(10px);
        display: block;
        width: rem(30px);
        height: rem(30px);
        background: rgba(255, 255, 255, 0.15)
          url("../assets/images/icons/close.svg") no-repeat center;
        background-size: 40%;
        border-radius: 50%;
        cursor: pointer;
      }
      &-text {
        text-transform: uppercase;
        text-align: center;
        font-size: rem(14px);
      }
      &-btn.btn {
        width: rem(240px);
        height: rem(38px);
        margin: rem(14px) auto 0;
        font-size: rem(16px);
      }
    }
  }
  &__date {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(10px);
    font-size: rem(16px);
    font-family: $medium;
    img {
      width: rem(24px);
      display: block;
      margin-right: rem(8px);
    }
  }
  &__rules {
    position: relative;
    margin-top: rem(10px);
    font-size: rem(20px);
    color: $brown;
    line-height: 1;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      @include size(100%, 250%);
      display: block;
      transform: translate(-50%, -50%);
    }
  }

  @media (orientation: landscape) {
    position: relative;
    flex-direction: column;
    height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
    background: url("../assets/images/hero/hero-2.png") no-repeat center right;
    background-size: auto 100%;
    .container {
      position: relative;
      padding-top: vh(115px);
      height: 100%;
    }
    &__subtitle {
      margin-bottom: vh(12px);
      font-size: vh(35px);
      letter-spacing: vh(1px);
      text-transform: uppercase;
    }
    &__title {
      img {
        height: vh(47px);
      }
    }
    &__suptitle {
      font-size: vh(35px);
      text-align: center;
    }
    &__products {
      position: absolute;
      bottom: vh(56px);
      left: rem(80px);
      height: vh(500px);
      margin-left: 0;
      margin-bottom: 0;
    }
    &-block {
      bottom: rem(48px);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn {
        width: auto;
      }
      &__popup {
        &-btn.btn {
          width: rem(240px) !important;
          height: rem(38px);
          margin: rem(14px) auto 0;
          font-size: rem(16px);
        }
      }
    }
    &__date {
      margin-bottom: vh(14px);
      font-size: vh(20px);
    }
    &__rules {
      margin-top: rem(20px);
      font-size: rem(22px);
    }
  }
  @media (min-width: $md) and (max-height: 750px) {
  }

  @media (max-width: $xlg) {
  }
}

$halfHeight: rem(54px);
$halfHeightSm: vh(40px);
$borderRadius: 0.15em;

.timer {
  margin-bottom: vh(10px);
  text-align: center;
  text-transform: uppercase;
  font-family: $bold;
  color: $orange;
  @media (orientation: landscape) {
    position: absolute;
    right: 0;
    margin-bottom: rem(20px);
  }
  &__title {
    margin-bottom: vh(5px);
    font-size: vh(18px);
    line-height: vh(22px);
    @media (orientation: landscape) {
      margin-bottom: rem(10px);
      font-size: rem(20px);
      line-height: rem(25px);
    }
  }
}
.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: vh(30px);
  line-height: 1;
  font-family: $bold;
  color: #000;
  @media (orientation: landscape) {
    font-size: rem(40px);
  }
  &__card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(4px);
    &::after {
      content: ":";
      line-height: 1;
      margin: rem(-10px) rem(8px) 0 rem(4px);
      color: $orange;
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $halfHeightSm;
      font-family: $bold;
      text-align: center;
      width: rem(40px);
      background: $orange;
      border-radius: $borderRadius;
      @media (orientation: landscape) {
        width: rem(40px);
        height: $halfHeight;
      }
    }
  }
}
.flip-clock {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: vh(30px);
  line-height: 1;
  font-family: $bold;
  color: #000;
  @media (orientation: landscape) {
    font-size: rem(40px);
  }
  &__piece {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: ":";
      line-height: 1;
      margin: rem(-10px) rem(8px) 0;
      color: $orange;
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }
}
.card__top {
  display: flex;
  gap: rem(4px);
}
.card__top span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $halfHeightSm;
  font-family: $bold;
  text-align: center;
  width: rem(40px);
  background: $orange;
  border-radius: $borderRadius;
  @media (orientation: landscape) {
    width: rem(40px);
    height: $halfHeight;
  }
}
</style>
