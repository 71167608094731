<template>
  <label class="form-file">
    <div class="form-file__caption">{{ label }}</div>
    <input
      type="file"
      class="form-file__input"
      @input="(e) => submitMedia(e.target.files)"
    />
    <div class="form-file__custom">
      <div class="form-file__chosen" v-if="chosenFileName[0]">
        {{ chosenFileName[0].name }}
        <div class="form-file__del" @click.prevent="deleteFile"></div>
      </div>
      <div class="form-file__caption" v-else>
        <span>ПРИКРЕПИТЬ ИЗОБРАЖЕНИЕ</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="12" fill="#FF9601" />
          <path
            d="M7 12L17 12"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 7L12 17"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    chosenFileName: {
      type: [FileList, Object],
    },
  },
  data: function () {
    return {
      // chosenFileName: ""
    };
  },
  methods: {
    submitMedia(e) {
      this.$emit("submitMedia", e);
    },
    deleteFile() {
      this.$emit("deleteFile");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-file {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  margin-bottom: rem(32px);
  @media (min-width: $md) and (orientation: landscape) {
    margin: 0 auto rem(40px);
  }
  &__input {
    position: absolute;
    left: -9999px;
    opacity: 0;
    visibility: hidden;
  }

  &__custom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: rem(56px);
    padding: 0 rem(20px);
    border-radius: rem(30px);
    border: rem(2px) solid $orange;
    cursor: pointer;
    img {
      margin-right: rem(6px);
    }
    span {
      font-size: rem(14px);
      color: $yellow;
      text-transform: uppercase;
      line-height: 1;
      transition: 0.4s;
    }
    &:hover {
      span {
        border-color: transparent;
      }
    }
  }

  &__caption {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: $bold;
    color: $yellow;
    font-size: rem(16px);
    svg {
      width: rem(24px);
      margin-left: rem(8px);
    }
  }

  &__btn {
    height: rem(32px);
    margin: 0;
    line-height: rem(32px);
    padding: 0 rem(28px);
    font-size: rem(13px);
    background: transparent;
  }

  &__chosen {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: rem(22px);
    text-transform: none;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: url("../../assets/images/icons/attach.svg") no-repeat center
      left;
    background-size: rem(16px);
    color: $yellow;
    font-size: rem(16px);
  }

  &__del {
    cursor: pointer;
    display: flex;
    width: rem(20px);
    height: rem(20px);
    margin: 0 0 0 auto;
    background: url("../../assets/images/icons/close.svg") no-repeat center;
    background-size: rem(10px);
  }
}
</style>
