<template>
  <Modal
    name="info"
    title="как Получить приз"
    size="lg"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="modal__text">
        <span
          >Для подтверждения статуса победителя необходимо
          в&nbsp;течение&nbsp;2&nbsp;(двух) рабочих дней с&nbsp;момента
          получения данного письма выслать на&nbsp;адрес электронной почты
          <br />Организатора
          <a href="mailto:info@nuri-promo.ru" class="_red _undeline-link"
            >info@nuri-promo.ru</a
          >
          следующие достоверные документы/сведения о&nbsp;себе:</span
        >
        <ul>
          <li v-for="(li, i) in list" :key="i">
            <i>{{ i + 1 }}</i>
            <span v-html="li.text"></span>
          </li>
        </ul>
        <p>
          <br />Участник обязан хранить оригинал указанного согласия
          на&nbsp;обработку персональных данных в&nbsp;течение&nbsp;1 (одного)
          года.
        </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      list: [
        {
          text: "Фамилия, имя, отчество;",
        },
        {
          text: "Контактный номер телефона;",
        },
        {
          text: "Дата рождения;",
        },
        {
          text: "Фото/скан страниц своего паспорта гражданина&nbsp;РФ (разворот с&nbsp;фотографией и&nbsp;страница с&nbsp;информацией о&nbsp;последнем месте регистрации);",
        },
        {
          text: "Фото/скан свидетельства ИНН;",
        },
        {
          text: 'Фото/скан подписанного согласия на&nbsp;обработку персональных данных <a href="/docs/policy.pdf" target="_blank">nuri-promo.ru/soglasiye.pdf;</a>',
        },
        {
          text: "Реквизиты банковской карты;",
        },

        {
          text: "Иную необходимую информацию по&nbsp;запросу Организатора.",
        },
      ],
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>
