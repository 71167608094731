<template>
  <Modal
    name="uploading_options"
    size="md"
    title="зарегистрировать чек"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="modal__buttons">
        <button
          class="btn btn--yellow"
          @click="
            closeModal('uploading_options'),
              toCheckUpload(),
              setWeborama(11),
              setMetrika('main_check_site')
          "
        >
          На сайте
        </button>
        <button
          class="btn btn--bordered _yellow"
          @click="
            closeModal('uploading_options'),
              openInNewTab(),
              setWeborama(12),
              setMetrika('main_check_bot')
          "
        >
          В чат-боте
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      text: "",
      title: "Спасибо!",
      successIcon: false,
      btnClose: false,
      checkButton: false,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    openInNewTab() {
      const promocode = this.$store.getters.user?.promocode || null;
      let click_id = localStorage.getItem("click_id") || null;
      window
        .open(
          `https://t.me/jaguar_energy_promo_bot${
            promocode ? `?start=${promocode}` : ""
          }${click_id ? `?start=separator${click_id}` : ""}`,
          "_blank"
        )
        .focus();
    },
    beforeOpen(event) {},
  },
};
</script>
<style lang="scss">
.success_modal-btn {
  margin-top: rem(30px);
}
</style>
