<template>
  <form class="recover">
    <inputText
      label="старый пароль"
      type="password"
      placeholder="••••••"
      maxlength="24"
      :field="oldPassword"
      @input="oldPassword = $event"
      :class="{
        error: validationStatus($v.oldPassword) || errorMessage.oldPassword,
      }"
      :error="
        (!$v.oldPassword.required && $v.oldPassword.$error
          ? 'Обязательное поле'
          : !$v.oldPassword.minLength && $v.oldPassword.$error
          ? 'Минимум 6 символа'
          : !$v.oldPassword.maxLength && $v.oldPassword.$error
          ? 'Максимум 24 символа'
          : '') || errorMessage.oldPassword
      "
    />
    <inputText
      label="новый пароль"
      type="password"
      maxlength="24"
      placeholder="••••••"
      :field="newPassword"
      @input="newPassword = $event"
      :class="{
        error: validationStatus($v.newPassword) || errorMessage.newPassword,
      }"
      :error="
        (!$v.newPassword.required && $v.newPassword.$error
          ? 'Обязательное поле'
          : !$v.newPassword.minLength && $v.newPassword.$error
          ? 'Минимум 6 символов'
          : !$v.newPassword.maxLength && $v.newPassword.$error
          ? 'Максимум 8 символа'
          : '') || errorMessage.newPassword
      "
    />
    <inputText
      label="повтори пароль"
      type="password"
      maxlength="24"
      placeholder="••••••"
      :field="repPassword"
      @input="repPassword = $event"
      :class="{
        error: validationStatus($v.repPassword) || errorMessage.repPassword,
      }"
      :error="
        (!$v.repPassword.required && $v.repPassword.$error
          ? 'Обязательное поле'
          : !$v.repPassword.minLength && $v.repPassword.$error
          ? 'Минимум 6 символов'
          : !$v.repPassword.maxLength && $v.repPassword.$error
          ? 'Максимум 24 символа'
          : '') || errorMessage.repPassword
      "
    />

    <div class="recover__footer">
      <button
        type="button"
        class="btn btn--primary"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Отправить<img
          src="../../assets/images/icons/spinner.svg"
          class="loader"
          alt=""
        />
      </button>
    </div>
  </form>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "RecoverForm",
  data() {
    return {
      ready: false,
      oldPassword: null,
      newPassword: null,
      repPassword: null,
      errorMessage: {
        oldPassword: null,
        newPassword: null,
        repPassword: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    oldPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(24),
    },
    newPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(24),
    },
    repPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(24),
    },
  },

  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        oldPassword: null,
        newPassword: null,
        repPassword: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.$store
          .dispatch("UpdateProfile", {
            old_password: this.oldPassword,
            pass: this.newPassword,
            passR: this.repPassword,
          })
          .then((r) => {
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.old_password) {
                [this.errorMessage.oldPassword] = r.error.old_password;
                fieldsError = true;
              }
              if (r.error && r.error.pass) {
                [this.errorMessage.newPassword] = r.error.pass;
                fieldsError = true;
              }
              if (r.error && r.error.passR) {
                [this.errorMessage.repPassword] = r.error.passR;
                fieldsError = true;
              }

              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  text: "Что-то пошло не так! " + r.error,
                });
              }
            } else {
              this.submitStatus = null;

              this.$modal.hide("change_password");
              this.$modal.show("common_success", {
                title: "изменение пароля",
                text: "ваш пароль изменен",
              });
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
  mounted() {},
  components: {
    inputText,
  },
};
</script>
