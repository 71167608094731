<template>
  <div>
    <form class="form" @submit.prevent="submitSendCode()">
      <ul class="modal-nav">
        <li class="modal-nav__item _pointer" @click="showLogInModal()">Вход</li>
        <li class="modal-nav__item active">Регистрация</li>
      </ul>
      <input type="hidden" name="phone" v-model="phoneNumber" />
      <div class="modal__subtitle">
        Введи код, который отправили вам на почту
      </div>
      <div class="form-wrapper-small">
        <inputText
          :field="code"
          placeholder="•••••"
          @input="code = $event"
          class="_centered-text"
          :class="{
            error:
              validationStatus($v.code) ||
              errorMessage.phoneNumber ||
              errorMessage.code,
          }"
          :error="
            (!$v.code.required && $v.code.$error
              ? 'Обязательное поле'
              : !$v.code.minLength && $v.code.$error
              ? 'Минимум ' + $v.code.$params.minLength.min + ' символов'
              : !$v.code.maxLength && $v.code.$error
              ? 'Максимум ' + $v.code.$params.maxLength.max + ' символов'
              : errorMessage.code) || errorMessage.phoneNumber
          "
        />
        <div v-if="!showNewCode" class="form__get-new-code">
          получить новый код <span>00:<span id="countdowntimer">45</span></span>
        </div>
        <div
          v-else
          @click="resendCode()"
          class="form__get-new-code _yellow _undeline-link"
        >
          Получить новый код
        </div>
        <div class="form__footer">
          <button
            class="btn btn--yellow"
            type="submit"
            :class="{ loading: submitStatus == 'PENDING' }"
          >
            зарегистрироваться
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  props: ["id", "formData"],
  components: {
    inputText,
  },
  data() {
    return {
      ready: false,
      showNewCode: false,
      code: null,
      phoneNumber: this.phone,
      errorMessage: {
        phoneNumber: null,
        code: null,
      },

      submitStatus: null,
    };
  },
  validations: {
    code: {
      required,
      minLength: minLength(4),
    },
  },
  mounted() {
    this.countDown();
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        code: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    showLogInModal() {
      this.$modal.hide("enter_code");
      this.$modal.show("authorization");
    },
    countDown() {
      let timeleft = 45;
      let downloadTimer = setInterval(
        function () {
          if (timeleft <= 0) {
            this.showNewCode = true;
            clearInterval(downloadTimer);
          } else {
            if (document.getElementById("countdowntimer"))
              document.getElementById("countdowntimer").textContent =
                timeleft > 9 ? timeleft : `0${timeleft}`;
          }
          timeleft -= 1;
        }.bind(this),
        1000
      );
    },
    setFormErrors(e) {
      const errors = e.response.data;
      this.submitStatus = null;

      let fieldsError = null;

      for (let index = 0; index < errors.length; index++) {
        const element = errors[index];
        if (element.field in this.errorMessage) {
          this.errorMessage[element.field] = element.message;
          fieldsError = true;
        }
      }

      if (errors && !fieldsError) {
        this.$modal.show("common_error", {
          text: "Что-то пошло не так! " + errors[0].message,
        });
      }
    },
    resendCode() {
      this.showNewCode = false;
      this.$store.dispatch("ResendCode", { id: this.id }).then(() => {
        this.countDown();
      });
    },
    submitSendCode() {
      console.log("submit");
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        this.$store
          .dispatch("SendCode", { id: this.id, fields: { code: this.code } })
          .then((r) => {
            this.submitStatus = null;
            this.$store.commit("SET_TOKEN", r.auth_key);
            // this.gtmEvent("enter", "form", "send");
            this.setMetrika("lk_registration");
            this.setWeborama(4);
            // this.gtmEvent("cabinet_success", "do", "internal");

            this.$store.dispatch("GetProfile").then((res) => {
              this.$root.$emit("onLogined");
              this.$modal.hide("enter_code");
              this.$modal.show("success_signup");
              this.$router.push("/profile");
            });
          })
          .catch((e) => {
            this.countDown();
            console.log(e.response);
            if (e.response) {
              this.setFormErrors(e);
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
