<template>
  <Modal name="games_popup" @closeModal="closeModal" size="md">
    <template v-slot:content>
      <div class="modal__games">
        <iframe
          id="game"
          :src="`${publicPath}games/index.html?auth_key=${$store.getters.token}`"
        >
        </iframe>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      src: null,
      text: null,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      const t = this;
      // Create IE + others compatible event handler
      const eventMethod = window.addEventListener
        ? "addEventListener"
        : "attachEvent";
      const eventer = window[eventMethod];
      const messageEvent =
        eventMethod == "attachEvent" ? "onmessage" : "message";

      // Listen to message from child window
      eventer(
        messageEvent,
        function (e) {
          console.log("parent received message!:  ", e.data);
          if (e.data === "closeTheGame") {
            t.$store.dispatch("GetProfile");
            t.closeModal("games_popup");
          } else if (e.data === "rating") {
            t.closeModal("games_popup");
            t.$modal.show("games_list_popup");
          } else if (e.data === "upload") {
            t.closeModal("games_popup");
            t.toCheckUpload();
          }
        },
        false
      );
      setTimeout(() => {
        document.getElementById("game").contentWindow.focus();
      }, 100);
      window.addEventListener("keydown", function (e) {
        console.log(e.key);

        if (e.key == "ArrowRight") {
          document
            .getElementById("game")
            .contentWindow.postMessage("ArrowRight", "*");
        } else if (e.key == "ArrowLeft") {
          document
            .getElementById("game")
            .contentWindow.postMessage("ArrowLeft", "*");
        }
      });
    },
  },
};
</script>
